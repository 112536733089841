<template>
  <sub-page
      v-model="$store.state.discount.pageData"
      :title="this.$t('LoyaltySystem')"
      icon="mdi-card-account-details-star-outline"

  >
    <h1>Loaylty</h1>
  </sub-page>
</template>

<script>
export default {
  name: "loyaltySystem"
}
</script>

<style scoped>

</style>